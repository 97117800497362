import React from "react";
import { Box, Typography, Grid, Card, CardContent, Button } from "@mui/material";

const ServicePlansSection = () => {
  const services = [
    {
      title: "Property Occupier",
      description:
        "Tailored solutions for tenants looking for the perfect place to call home.",
      features: [
        "Wide property listings",
        "Flexible lease terms",
        "Tenant support services",
      ],
    },
    {
      title: "Property Investor",
      description:
        "Helping investors find lucrative opportunities in real estate.",
      features: [
        "Market insights",
        "High-yield properties",
        "Portfolio management",
      ],
    },
    {
      title: "Property Partner",
      description:
        "Collaborate with us to expand your business in the property market.",
      features: [
        "Joint venture opportunities",
        "Business development support",
        "Shared resources and tools",
      ],
    },
    {
      title: "Property Owner",
      description:
        "Effortless management solutions for property owners of all types.",
      features: [
        "Tenant screening",
        "Property maintenance",
        "Maximized rental income",
      ],
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#FFF8E5", // Light background matching the theme
        padding: "40px 20px",
      }}
    >
      {/* Service Cards */}
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                backgroundColor: "#FFF",
                border: "1px solid #FAC710",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                height: "100%",
              }}
            >
              <CardContent>
                {/* Service Title */}
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#FAC710",
                    marginBottom: "10px",
                  }}
                >
                  {service.title}
                </Typography>

                {/* Service Description */}
                <Typography
                  variant="body2"
                  sx={{ color: "#555", marginBottom: "20px" }}
                >
                  {service.description}
                </Typography>

                {/* Features */}
                <ul style={{ paddingLeft: "16px", marginBottom: "20px" }}>
                  {service.features.map((feature, idx) => (
                    <li key={idx} style={{ color: "#333" }}>
                      {feature}
                    </li>
                  ))}
                </ul>

                {/* Learn More Button */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#FAC710",
                    color: "#FFF",
                    "&:hover": {
                      backgroundColor: "#e6b907",
                    },
                  }}
                >
                  Learn More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServicePlansSection;