import React from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

const WhyRealMovvSection = () => {
  const features = [
    {
      title: "Seamless Navigation",
      description: "Effortlessly browse through properties with our user-friendly interface.",
    },
    {
      title: "Verified Listings",
      description: "Access trustworthy and verified property details, ensuring no surprises.",
    },
    {
      title: "Expert Support",
      description: "Our team is here to assist you every step of the way.",
    },
    {
      title: "Competitive Pricing",
      description: "Find properties that fit your budget without compromising on quality.",
    },
  ];

  return (
    <Box
      sx={{ // A soft background complementing #FAC710
        padding: "0px 20px",
      }}
    >
      {/* Features Section */}
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                backgroundColor: "#FFF",
                border: "1px solid #FAC710",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                height: "100%",
              }}
            >
              <CardContent>
                <CheckCircleOutline
                  sx={{
                    fontSize: "40px",
                    color: "#FAC710",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    marginBottom: "10px",
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "#555" }}>
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhyRealMovvSection;