import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
} from "@mui/material";

const TabbedModal = ({ open, onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
      <Box sx={modalStyle}>
        {/* Modal Header */}
        <Typography
          id="modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", marginBottom: "20px", color: "#FAC710" }}
        >
          Welcome to RealMovv
        </Typography>

        {/* Tabs */}
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{ marginBottom: "20px" }}
        >
          <Tab label="Login" />
          <Tab label="Register" />
        </Tabs>

        {/* Tab Content */}
        {tabIndex === 0 && (
          <Box component="form">
            <TextField
              fullWidth
              label="Email"
              type="email"
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              margin="normal"
              required
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#FAC710",
                color: "#FFF",
                marginTop: "20px",
                "&:hover": {
                  backgroundColor: "#e6b907",
                },
              }}
            >
              Login
            </Button>
          </Box>
        )}

        {tabIndex === 1 && (
          <Box component="form">
            <TextField
              fullWidth
              label="Full Name"
              type="text"
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Email"
              type="email"
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              margin="normal"
              required
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#FAC710",
                color: "#FFF",
                marginTop: "20px",
                "&:hover": {
                  backgroundColor: "#e6b907",
                },
              }}
            >
              Register
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default TabbedModal;