import { Box, FormControl, InputLabel, Select, Toolbar, IconButton, Typography, Menu, Container,Avatar,Button,Tooltip, MenuItem, Card, CardActions, CardContent,CardMedia } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "./home.css";
import PropertyCard from "./property";
import TrustedBySection from "./trustee";
import WhyRealMovvSection from "./whyUs";
import ServicePlansSection from "./services"
import TestimonialSection from "./testimonials"
import ExploreOtherSolutions from "./solutions"
import Footer from "./footer"
import TabbedModal from "./modalform"


const MediaCard = function () {
    return (
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          sx={{ height: 140 }}
          image="/house.jpg"
          title="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Land for Sale
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Residential land for sale at PRAMPRAM- DON'T JUST DREAM IT, OWN IT.
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
      </Card>
    );
}

const pages = [
    {name: 'Home', url: '/pages/Home'},
    {name: 'Properties', url: '/pages/Properties'},
    {name: 'For Sale', url: '/pages/Pricing'},
    {name: 'For Rent', url: '/pages/Home'},
    {name: 'Developments', url: '/pages/Home'},
    {name: 'Agents', url: '/pages/Home'},
    {name: 'Login', url: '/pages/Home'}
];
const settings = ['./house1.webp', './house2.webp', './house3.jpg', './house4.webp', './house5.jpeg', './house6.jpg'];

function Home() {
    const [pType, setpType] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [bed, setBed] = React.useState('');
    const [open, setModal] = React.useState(false);

    const handleChange = (event) => {
        setpType(event.target.value);
    };

    const handleChange2 = (event) => {
        setLocation(event.target.value);
    };

    const handleChange3 = (event) => {
        setBed(event.target.value);
    };

  return (
    <Box>
        <TabbedModal open={open} onClose={() => setModal(false)}/>
        <Box sx={{bgcolor: '#FAC710', mt: 0, p: 1.5, display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'static'}}>
            <Box sx={{display: 'flex',width: '20%'}}>
                <Box
                sx={{display: 'flex', flexDirection: 'row', alignItems: 'center',gap: 2}}
                
                >
                    <Typography variant='p' component={'a'} sx={{display: {sm: 'block', md: 'none'}}} >|||</Typography>
                    <img 
                        src="logo.png" 
                        alt="Background"
                        className='logoImage' 
                    />
                    <Typography sx={{textDecoration: 'none', color: 'black'}} component="a" href="#home" variant='h4'>RealMovv</Typography>
                </Box>
            </Box>

            <Box sx={{display: 'flex',width: '80%', justifyContent: 'flex-end', gap: 4}}>
                {pages.map((page)=>(
                    <Typography sx={{textDecoration: 'none', color: 'black', ':hover': {color: 'red'}}} component="a" href={`#${page.url}`} onClick={()=>{page.name == "Login"?setModal(true):setModal(false)}} variant='h6'>{page.name}</Typography>
                ))}
            </Box>
        </Box>

        <Box
            sx={{
                width: "100%",
                height: "450px",
                backgroundImage: `url(/house.jpg)`,
                backgroundSize: "cover", // Ensures the image covers the entire box
                backgroundPosition: "center", // Centers the image
                backgroundRepeat: "no-repeat", // Prevents tiling
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}
        >
            <Box sx={{display: 'flex',width:'100%',position: 'absolute',backgroundColor: 'rgba(0, 0, 0, 0.685)',height: '100%'}}></Box>
            <Box sx={{zIndex: 1,display: 'flex',flexDirection: 'column', width: '100%',justifyContent: 'center',alignItems: 'center'}}>
                <Typography variant='h4' sx={{color: '#fac710'}}>Find your dream home with just a click away!</Typography>
                <Box sx={{my: 3,display:'flex',flexDirection:'row',gap: 2,width: '80%'}}>
                    <Box sx={{ minWidth: 120, display: 'flex', width: '30%', backgroundColor: 'white' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" className='demo-simple-select-label'>Type</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={pType}
                            label="Age"
                            onChange={handleChange}
                            >
                            <MenuItem value={"Land"}>Land</MenuItem>
                            <MenuItem value={"House"}>House</MenuItem>
                            <MenuItem value={"Apartment"}>Apartment</MenuItem>
                            <MenuItem value={"Office"}>Office</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ minWidth: 120, display: 'flex', width: '30%', backgroundColor: 'white' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" className='demo-simple-select-label'>Location</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={location}
                            label="Age"
                            onChange={handleChange2}
                            >
                            <MenuItem value={"Accra"}>Accra</MenuItem>
                            <MenuItem value={"Tamale"}>Tamale</MenuItem>
                            <MenuItem value={"Kumasi"}>Kumasi</MenuItem>
                            <MenuItem value={"Tema"}>Tema</MenuItem>
                            <MenuItem value={"East Legon"}>East Legon</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ minWidth: 120, display: 'flex', width: '30%', backgroundColor: 'white' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" className='demo-simple-select-label'>Beds</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={bed}
                            label="Age"
                            onChange={handleChange3}
                            >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box>
                    <Button variant="contained" sx={{backgroundColor: '#fac710',py:1.5,px:5,color: 'black',":hover":{backgroundColor: 'black',color: '#fac710'}}}>Search</Button>
                </Box>
            </Box>
        </Box>

        <Box sx={{display: 'flex',flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', my: 10}}>
            <Typography variant='h4' sx={{
                fontWeight: "bold",
                color: "#FAC710",
                marginBottom: "20px",
                }}>Latest Properties</Typography>
            <Typography variant='span' className='headerStyle'></Typography>
            <Typography variant='p' sx={{my: 2}}>Browse some of our latest properties on RealMoovv</Typography>
        </Box>

        <Box sx={{width: '100%',mt: 2,mb: 2}}>
            <Box sx={{display: 'flex', width: '100%',gap: 2, flexWrap: 'wrap', justifyContent: 'center'}}>
                {settings.map((image,key)=>(
                    <PropertyCard image={image} key={key} price={1200.00} description={"3 Bedroom Incomplete House For Sale at Kigamboni"} beds={3} />
                ))}
            </Box>
        </Box>

        <Box sx={{width: '100%',backgroundColor: "#FFF8E5",pt: 3}}>
            <Box sx={{display: 'flex',flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', my: 10}}>
                <Typography variant='h4' sx={{
                    fontWeight: "bold",
                    color: "#FAC710",
                    marginBottom: "20px",
                    }}>Service Plans</Typography>
                <Typography variant='span' className='headerStyle'></Typography>
                <Typography variant='p' sx={{my: 1}}>Join the hundreds of businesses relying on RealMoovv.</Typography>
            </Box>
            <Box sx={{display: 'flex', width: '100%',gap: 2, flexWrap: 'wrap', justifyContent: 'center'}}>
                <ServicePlansSection/>
            </Box>
        </Box>

        <Box sx={{width: '100%',pt: 2,pb: 20}}>
            <Box sx={{display: 'flex',flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', my: 10}}>
                <Typography variant='h4' sx={{
                    fontWeight: "bold",
                    color: "#FAC710",
                    marginBottom: "20px",
                    }}>Why RealMovv</Typography>
                <Typography variant='span' className='headerStyle'></Typography>
                <Typography variant='p' sx={{my: 1}}>Join the hundreds of businesses relying on RealMoovv.</Typography>
            </Box>
            <Box sx={{display: 'flex', width: '100%',flexWrap: 'wrap', justifyContent: 'center'}}>
                <WhyRealMovvSection />
            </Box>
        </Box>

        <Box sx={{width: '100%',backgroundColor: "#FFF8E5",pt: 3}}>
            <Box sx={{display: 'flex',flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', my: 10}}>
                <Typography variant='h4' sx={{
                    fontWeight: "bold",
                    color: "#FAC710",
                    marginBottom: "20px",
                    }}>Testimonials | Reviews & Ratings</Typography>
                <Typography variant='span' className='headerStyle'></Typography>
                <Typography variant='p' sx={{my: 1}}>Join the hundreds of businesses relying on RealMoovv.</Typography>
            </Box>
            <Box sx={{display: 'flex', width: '100%',gap: 2, flexWrap: 'wrap', justifyContent: 'center'}}>
                <TestimonialSection/>
            </Box>
        </Box>

        <Box sx={{width: '100%',pt: 2,pb: 1}}>
            <Box sx={{display: 'flex',flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', my: 10}}>
                <Typography variant='h4' sx={{
                    fontWeight: "bold",
                    color: "#FAC710",
                    marginBottom: "20px",
                    }}>Explore Our Other Solutions</Typography>
                <Typography variant='span' className='headerStyle'></Typography>
                <Typography variant='p' sx={{my: 1}}>Join the hundreds of businesses relying on RealMoovv.</Typography>
            </Box>
            <Box sx={{display: 'flex', width: '100%',flexWrap: 'wrap', justifyContent: 'center'}}>
                <ExploreOtherSolutions />
            </Box>
        </Box>

        <Box sx={{width: '100%',backgroundColor: "#FFF8E5",pt: 3}}>
            
            <Box sx={{display: 'flex', width: '100%',gap: 2, flexWrap: 'wrap', justifyContent: 'center'}}>
                <TrustedBySection/>
            </Box>
        </Box>

        <Footer />
    </Box>
  )
}

export default Home