import React from "react";
import { Box, Typography, Grid, Avatar } from "@mui/material";

const TrustedBySection = () => {
  const companyLogos = [
    "./logo1.webp", // Replace with actual company logos
    "./logo2.jpg",
    "./logo3.avif",
    "./logo4.avif",
    "./logo1.webp", // Replace with actual company logos
    "./logo2.jpg",
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: "10px 20px 40px 20px",
        textAlign: "center",
      }}
    >
        <Typography sx={{display: 'flex',width:'90%',justifyContent: 'flex-start',py:3,fontWeight: "bold",
                    color: "#FAC710"}} variant="h4">We are Trusted By</Typography>
      {/* Logos Section */}
      <Grid container spacing={4} justifyContent="center">
        {companyLogos.map((logo, index) => (
          <Grid item key={index}>
            <Avatar
              src={logo}
              alt={`Company ${index + 1}`}
              sx={{
                width: 140,
                height: 140,
                border: "2px solid #FAC710",
                borderRadius: "50%",
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="body2"
        sx={{
          color: "#333",
          marginTop: "20px",
        }}
      >
        Join the hundreds of businesses relying on us.
      </Typography>
    </Box>
  );
};

export default TrustedBySection;