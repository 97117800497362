import React from "react";
import { Box, Typography, Grid, Card, CardContent, Avatar, Rating } from "@mui/material";

const TestimonialSection = () => {
  const testimonials = [
    {
      name: "John Doe",
      role: "Property Owner",
      review:
        "RealMovv made managing my property seamless. Their team is professional and always ready to help!",
      rating: 5,
      avatar: "https://via.placeholder.com/80", // Replace with customer photo
    },
    {
      name: "Jane Smith",
      role: "Property Investor",
      review:
        "With RealMovv, I found the best investment opportunities. Highly recommend their services!",
      rating: 4.5,
      avatar: "https://via.placeholder.com/80",
    },
    {
      name: "Emily Johnson",
      role: "Property Occupier",
      review:
        "Finding my dream home was a breeze with RealMovv. Exceptional service and support!",
      rating: 3,
      avatar: "https://via.placeholder.com/80",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#FFF8E5",
        padding: "40px 20px",
        textAlign: "center",
      }}
    >

      {/* Testimonial Cards */}
      <Grid container spacing={4}>
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                backgroundColor: "#FFF",
                border: "1px solid #FAC710",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <CardContent>
                {/* Avatar */}
                <Avatar
                  src={testimonial.avatar}
                  alt={testimonial.name}
                  sx={{
                    width: 80,
                    height: 80,
                    margin: "0 auto 10px",
                    border: "2px solid #FAC710",
                  }}
                />

                {/* Name & Role */}
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#333", marginBottom: "5px" }}
                >
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" sx={{ color: "#666" }}>
                  {testimonial.role}
                </Typography>

                {/* Review */}
                <Typography
                  variant="body2"
                  sx={{ color: "#555", marginTop: "10px", marginBottom: "10px" }}
                >
                  "{testimonial.review}"
                </Typography>

                {/* Rating */}
                <Rating
                  value={testimonial.rating}
                  precision={0.5}
                  readOnly
                  sx={{ color: "#FAC710" }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TestimonialSection;