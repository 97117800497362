import React from "react";
import { Box, Grid, Typography, Link, IconButton } from "@mui/material";
import { Facebook, Twitter, LinkedIn, Instagram } from "@mui/icons-material";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#333",
        color: "#FFF",
        padding: "40px 20px",
      }}
    >
      <Grid container spacing={4}>
        {/* Company Overview Section */}
        <Grid item xs={12} sm={6} md={4}>
          <img
            src="logo.png" // Replace with your logo URL
            alt="Company Logo"
            style={{ width: "150px", marginBottom: "20px", borderRadius: '50%' }}
          />
          <Typography variant="body2" sx={{ lineHeight: 1.8 }}>
          Get into International Real Estate from the comfort of your home, with a simplified one-stop Property platform for all your property needs.
          </Typography>
        </Grid>

        {/* Links Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" sx={{ marginBottom: "10px", color: "#FAC710" }}>
            Quick Links
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Link href="#about-us" color="inherit" underline="hover" sx={{ marginBottom: "5px" }}>
              About Us
            </Link>
            <Link href="#services" color="inherit" underline="hover" sx={{ marginBottom: "5px" }}>
              Services
            </Link>
            <Link href="#contact" color="inherit" underline="hover" sx={{ marginBottom: "5px" }}>
              Contact
            </Link>
            <Link href="#faq" color="inherit" underline="hover">
              FAQ
            </Link>
          </Box>
        </Grid>

        {/* Social Media Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" sx={{ marginBottom: "10px", color: "#FAC710" }}>
            Follow Us
          </Typography>
          <Box>
            <IconButton
              href="https://facebook.com" // Replace with your links
              target="_blank"
              sx={{ color: "#FAC710" }}
            >
              <Facebook />
            </IconButton>
            <IconButton
              href="https://twitter.com"
              target="_blank"
              sx={{ color: "#FAC710" }}
            >
              <Twitter />
            </IconButton>
            <IconButton
              href="https://linkedin.com"
              target="_blank"
              sx={{ color: "#FAC710" }}
            >
              <LinkedIn />
            </IconButton>
            <IconButton
              href="https://instagram.com"
              target="_blank"
              sx={{ color: "#FAC710" }}
            >
              <Instagram />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {/* Copyright Section */}
      <Box
        sx={{
          borderTop: "1px solid #555",
          marginTop: "20px",
          paddingTop: "10px",
          textAlign: "center",
        }}
      >
        <Typography variant="body2" sx={{ color: "#AAA" }}>
          © {new Date().getFullYear()} RealMovv. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;