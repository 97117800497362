import React from 'react';
import { Card, CardMedia, CardContent, CardActions, Typography, Button } from '@mui/material';
import BedIcon from '@mui/icons-material/Bed';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ArrowForward } from "@mui/icons-material";

const PropertyCard = ({ image, price, beds, description, link }) => {
  return (
    <Card 
      sx={{
        maxWidth: 345,
        textDecoration: 'none',
        '&:hover': { boxShadow: 6 }
      }}
      component="a"
      href={link}
    >
      {/* Property Image */}
      <CardMedia
        component="img"
        height="200"
        image={image}
        alt="Property Image"
      />

      {/* Property Details */}
      <CardContent>
        <Typography variant="h6" gutterBottom>
          <AttachMoneyIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
          {price}
        </Typography>
        <Typography variant="body1" color="text.secondary" display="flex" alignItems="center">
          <BedIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
          {beds} Beds
        </Typography>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ mt: 1, lineHeight: 1.5 }}
        >
          {description}
        </Typography>
      </CardContent>

      {/* Call to Action */}
      <CardActions>
      <Button
        variant="text"
        endIcon={<ArrowForward />}
        href={"#"}
        sx={{
          color: "#FAC710",
          margin: "10px",
          alignSelf: "flex-start",
          textTransform: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        Explore
      </Button>
      </CardActions>
    </Card>
  );
};

export default PropertyCard;