import React from "react";
import { Box, Typography, Grid, Card, CardContent, Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

const ExploreOtherSolutions = () => {
  const solutions = [
    {
      title: "Commercial Property Solutions",
      description: "Optimize your commercial investments with our expert guidance.",
      link: "#",
    },
    {
      title: "Residential Property Services",
      description: "Find your perfect home or get help managing your residential properties.",
      link: "#",
    },
    {
      title: "Real Estate Consulting",
      description: "Get expert insights and advice to maximize your property’s potential.",
      link: "#",
    },
    {
      title: "Short-Term Rentals",
      description: "Manage and monetize your short-term rental properties effortlessly.",
      link: "#",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#FFF",
        padding: "40px 20px",
      }}
    >
      {/* Solutions Cards */}
      <Grid container spacing={4}>
        {solutions.map((solution, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                backgroundColor: "#FFF8E5",
                border: "1px solid #FAC710",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CardContent>
                {/* Solution Title */}
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#FAC710",
                    marginBottom: "10px",
                  }}
                >
                  {solution.title}
                </Typography>

                {/* Solution Description */}
                <Typography
                  variant="body2"
                  sx={{ color: "#555", marginBottom: "20px" }}
                >
                  {solution.description}
                </Typography>
              </CardContent>

              {/* Explore Button */}
              <Button
                variant="text"
                endIcon={<ArrowForward />}
                href={solution.link}
                sx={{
                  color: "#FAC710",
                  margin: "10px",
                  alignSelf: "flex-start",
                  textTransform: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Explore
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ExploreOtherSolutions;